import React from 'react';
import AppComponent from './AppComponent';


const App = () => {
  return (
    <AppComponent/>
  );
};

export default App;

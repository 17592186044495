/**
 * Created by traffii on 6/15/17.
 */
import React from 'react'

class VoiceList extends React.Component {
    render() {
        return (
            <div>
                <form class="option" >
                    <label for="Głos">Głos: </label>
                    <select {...this.props.tabIndex}  onChange={event => this.props.handleVoiceChoice(event.target.value)} name="voice" id="voice">
                        {
                            this.props.voices.map((voice, index) => (
                                <option key={index} value={index}>{voice.name}</option>
                            ))
                        }
                    </select>
                </form>
            </div>
        );
    };
}

export default VoiceList

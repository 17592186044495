/**
 * Created by traffii on 6/18/17.
 */
import _ from 'lodash'

class TimeCounter {
    MILI = 1000;
    startTime = null;
    allTime = 0;

    constructor() {
        this.startTime = new Date();
        this.allTime = 0;
    }

    start() {
        this.startTime = new Date();
    }

    pause() {
        this.allTime = this.allTime + (new Date() - this.startTime);
    }

    reset() {
        this.startTime = new Date();
        this.allTime = 0;
    }

    getElapsedTimeInMilis() {
        if(this.startTime === null) throw new Error("Timer not started!");
        return this.allTime;
    }
}

export default TimeCounter;
/**
 * Created by traffii on 6/17/17.
 */

import React from 'react'
import AbstractTextAreaComponent from './AbstractTextAreaComponent'
import TimeCounter from './TimeCounter'
import HumanizeDuration from 'humanize-duration'

class ExerciseComponent extends AbstractTextAreaComponent {
    state = {
        numberOfQuestions: 0,
        numberOfQuestionsToEndExercise: 0,
        numberOfGuessed: 0
    }
    timeCounter = new TimeCounter();
    SPACE_KEYCODE = 32;

    componentDidMount() {
        console.log("Mounted");
        this.resetExercise();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.exerciseNumber !== this.state.numberOfQuestionsToEndExercise) {
            this.setState({
                numberOfQuestions: nextProps.exerciseNumber,
                numberOfQuestionsToEndExercise: nextProps.exerciseNumber
            });
        }
    }

    handleKeyPress = (e) => {
        e.preventDefault()
        this.addKeyToUsedList(e.keyCode)
        if (this.isExitCombinationOccured()) {
            document.getElementById('learnHeader').focus();
            return;
        }

        if (e.keyCode === this.SPACE_KEYCODE && !this.state.exerciseStarted) {
            this.startExercise();
            return;
        }

        if (!this.state.exerciseStarted) {
            this.props.speak("Wciśnij spację aby rozpocząć");
            return;
        }

        if (this.isAnswerRight(e.keyCode)) {
            this.timeCounter.pause();
            this.props.speak("Poprawnie");
        }
        else {
            this.timeCounter.pause();
            this.props.speak("Niepoprawnie");
        }

        if (this.isExerciseShouldBeEnd()) {
            this.setState({
                exerciseStarted: false
            }, () => {
                this.showResults();
                this.resetExercise();
            })
            return;
        }

        this.randomQuestion();

    };
    
    startExercise() {
        console.log("Exercise started!");
        this.props.speak("START");
        this.setState({
            exerciseStarted: true
        });
        this.randomQuestion();
    }

    randomQuestion() {
        let randomedQuestion = _.sample(this.keyPair);
        this.setState({
            currentQuestion: randomedQuestion,
            numberOfQuestionsToEndExercise: this.state.numberOfQuestionsToEndExercise - 1
        });


        this.props.speak(randomedQuestion, this.onQuestionSpeakEnd.bind(this));
    }

    onQuestionSpeakEnd() {
        this.timeCounter.start();
    }

    isAnswerRight(keyCode) {
        console.log("Randomed question: " + this.state.currentQuestion);
        console.log(keyCode)
        if(parseInt(this.getKeyByValue(this.keyPair, this.state.currentQuestion)) === keyCode) {
            this.setState({
                numberOfGuessed: this.state.numberOfGuessed + 1
            })
            return true;
        }
        return false;
    }

    isExerciseShouldBeEnd() {
        if (this.state.numberOfQuestionsToEndExercise === 0) {
            return true;
        }

        return false;
    }

    showResults() {
        var timeElapsedInHumanFormat = HumanizeDuration(this.timeCounter.getElapsedTimeInMilis(), {language: 'pl'});
        this.props.speak("Odpowiedziano poprawnie na " + this.state.numberOfGuessed + " z " + this.state.numberOfQuestions);
        this.props.speak("Twój czas to " + timeElapsedInHumanFormat );
        console.log("Odpowiedziano poprawnie na " + this.state.numberOfGuessed + " z " + this.state.numberOfQuestions);
        console.log("Your time is: " + timeElapsedInHumanFormat);
    }

    resetExercise() {
        this.timeCounter.reset();
        this.setState ({
            numberOfQuestions: this.props.exerciseNumber,
            numberOfQuestionsToEndExercise: this.props.exerciseNumber,
            numberOfGuessed: 0
        });
    }

    getKeyByValue(object, value) {
        return Object.keys(object).find(key => object[key] === value);
    }

    onEnterTextArea = () => {
        if (!this.state.exerciseStarted) {
            console.log("Exercise component focused");
            this.props.speak("Wciśnij spację aby rozpocząć, żeby wyjść naciśnij trzy razy przycisk tab");
        }
    }

    render() {
        return (
            <div>
                <textarea onFocus={this.onEnterTextArea} aria-label="Wciśnij spację aby rozpocząć" id="exerciseArea" {...this.props.tabIndex} cols="40" rows="20" style={{minWidth: "100%"}} placeholder="Aby rozpocząć naciśnij spację"  onKeyDown={this.handleKeyPress}/>
            </div>
        );
    };
}

export default ExerciseComponent;
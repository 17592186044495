/**
 * Created by traffii on 6/15/17.
 */
import React from 'react'
import _ from 'lodash'
import AbstractTextAreaComponent from './AbstractTextAreaComponent'

class InputConsole extends AbstractTextAreaComponent {
   
    keyWhichShouldBeOverrided = [37, 38, 39, 40, 192];

    handleKeyPress = (e) => {
        e.preventDefault()
        this.addKeyToUsedList(e.keyCode)
        if (this.isExitCombinationOccured()) {
            document.getElementById('exerciseHeader').focus();
            return;
        }

        if (!_.includes(this.keyWhichShouldBeOverrided, e.keyCode)) {
            this.props.speak(e.key);
        }
        else {
            this.props.speak(this.keyPair[e.keyCode]);
        }

        console.log("Key code: " + e.keyCode);
        console.log("KeyPair: " + this.keyPair[e.keyCode]);
        console.log("Native key: " + e.key);

    };

    onEnterTextArea = () => {
        console.log("Learning component focused");
        this.props.speak("Wciśnij dowolny klawisz, aby rozpocząć naukę, aby wyjść naciśnij 3 razy przycisk TAB");
    }

    render() {
        return (
            <div>
                <textarea onFocus={this.onEnterTextArea} id="learningArea" aria-label="Wciśnij dowolny klawisz, aby rozpocząć naukę, aby wyjść naciśnij 3 razy przycisk TAB" {...this.props.tabIndex} cols="40" rows="20" style={{minWidth: "100%"}} placeholder="Wybierz to pole, a następnie wciśnij dowolny klawisz, aby rozpocząć naukę, aby wyjść naciśnij 3 razy przycisk TAB" onKeyDown={this.handleKeyPress}/>
            </div>
        );
    };
}

export default InputConsole